import React, { createContext, useState, useEffect } from "react";
import { useUserSites } from "../hooks/useUsers";
import { Spinner } from 'react-bootstrap';

const SiteAccessContext = createContext({
    assignedSites: []
});

export const SiteAccessProvider = ({ children }) => {

    const { userSites, loading } = useUserSites();

    const [assignedSites, setAssignedSites] = useState();

    useEffect(() => {
        setAssignedSites(userSites);
    }, [userSites]);

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    return (
        <SiteAccessContext.Provider value={{ assignedSites }}>
            {children}
        </SiteAccessContext.Provider>
    )
}

export default SiteAccessContext;