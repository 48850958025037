import React from 'react';
import { Route } from 'react-router';
import { Routes } from "react-router-dom";
import { client as publicClientApplication } from './utilities/AuthUtils';
import { usePrivileges } from './hooks/usePrivileges';

import "./Components/NavBar.css";
import { AlertProvider } from './Components/Alerts/AlertContext';
import NotificationAlert from './Components/Alerts/NotificationAlert';

import NavBar from "./Components/NavBar";
import Footer from './Components/Footer';

// Pages
import Home from './Components/Pages/Home';
import LogDetails from './Components/Pages/Logs/LogDetails';
import ComponentGroupLookupGrid from './Components/Pages/ComponentGroupLookup/ComponentGroupLookupGrid';
import SiteGrid from './Components/Pages/Site/SiteGrid';
import ILRComponentMappingGrid from './Components/Pages/ILRComponentMapping/ILRComponentMappingGrid';
import ILRSiteGrid from './Components/Pages/ILRSite/ILRSiteGrid';
import ComponentMappingGrid from './Components/Pages/ComponentMapping/ComponentMappingGrid';
import CofAConfiguration from "./Components/Pages/CofAConfiguration/CofAConfiguration"
import CofQConfiguration from "./Components/Pages/CofQConfiguration/CofQConfiguration"
import ILRInspectionLotRequestGrid from './Components/Pages/ILRInspectionLotRequest/ILRInspectionLotRequestGrid';
import ILRMaterialMapGrid from './Components/Pages/ILRMaterialMap/ILRMaterialMapGrid';
import DocLand from "./Components/Pages/Documentation/DocLand"
import GEMSLanding from "./Components/Pages/Documentation/GEMS/GEMSLanding"
import SampleFind from "./Components/Pages/Documentation/GEMS/SampleFind"
import ILRMaterialMapDoc from "./Components/Pages/Documentation/GEMS/ILRMaterialMapDoc"
import ILRComponentMappingDoc from "./Components/Pages/Documentation/GEMS/ILRComponentMappingDoc"
import ILRSiteDoc from "./Components/Pages/Documentation/GEMS/ILRSiteDoc"
import ILRReprocess from "./Components/Pages/Documentation/GEMS/ILRReprocess";
import GOMLanding from "./Components/Pages/Documentation/GOM/GOMLanding"
import GOMSiteDoc from "./Components/Pages/Documentation/GOM/GOMSiteDoc"
import ComponentGroupLookupDoc from "./Components/Pages/Documentation/GOM/ComponentGroupLookupDoc"
import ComponentMappingDoc from "./Components/Pages/Documentation/GOM/ComponentMappingDoc"
import TranslationDoc from "./Components/Pages/Documentation/GOM/TranslationDoc"
import GOMSampleFind from "./Components/Pages/Documentation/GOM/GOMSampleFind"
import SupportLanding from "./Components/Pages/Documentation/Support/SupportLanding"
import TranslationGrid from './Components/Pages/Translation/TranslationGrid';
import Architecture from "./Components/Pages/Documentation/Support/Architecture";
import IlrProcess from "./Components/Pages/Documentation/Support/IlrProcess";
import IlrStatus from "./Components/Pages/Documentation/Support/IlrStatus";
import GomProcess from "./Components/Pages/Documentation/Support/GomProcess";
import Resources from "./Components/Pages/Documentation/Support/Resources";
import GomRequestGrid from './Components/Pages/GOMTestFramework/GomRequestGrid';
import { SiteAccessProvider } from "./contexts/SiteAccessContext";

const App = () => {

  const account = publicClientApplication.getAllAccounts()[0];
  const { contributor, admin } = usePrivileges();

  return (
      <>
          <AlertProvider>
              <NotificationAlert></NotificationAlert>
              <SiteAccessProvider>
                  <NavBar />
                  <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/logs" element={<LogDetails />} />

                      <Route path="/gom" element={<GOMLanding />} />
                      <Route path="/gom/ComponentGroupLookups" element={<ComponentGroupLookupGrid />} />
                      <Route path="/gom/sites" element={<SiteGrid />} />
                      <Route path="/gom/ComponentMappings" element={<ComponentMappingGrid />} />
                      <Route path="/gom/translations" element={<TranslationGrid />} />
                      <Route path="/gom/requests" element={<GomRequestGrid />} />

                      <Route path="/gems" element={<GEMSLanding />} />
                      <Route path="/gems/sites" element={<ILRSiteGrid />} />
                      <Route path="/gems/ILRComponentMappings" element={<ILRComponentMappingGrid />} />
                      <Route path="/gems/ILRInspectionLotRequests" element={<ILRInspectionLotRequestGrid />} />
                      <Route path="/gems/ILRMaterialMaps" element={<ILRMaterialMapGrid />} />

                      <Route path="/documentation" element={<DocLand />} />
                      <Route path="/documentation/CofAConfiguration" element={<CofAConfiguration />} />
                      <Route path="/documentation/CofQConfiguration" element={<CofQConfiguration />} />
                      <Route path="/documentation/gems/SampleFind" element={<SampleFind />} />
                      <Route path="/documentation/gems/ILRMaterialMappings" element={<ILRMaterialMapDoc />} />
                      <Route path="/documentation/gems/ILRComponentMappings" element={<ILRComponentMappingDoc />} />
                      <Route path="/documentation/gems/sites" element={<ILRSiteDoc />} />
                      <Route path="/documentation/gems/ILRReprocess" element={<ILRReprocess />} />
                      <Route path="/documentation/gems/IlrProcess" element={<IlrProcess />} />
                      <Route path="/documentation/gems/IlrStatus" element={<IlrStatus />} />

                      <Route path="/documentation/gom/sites" element={<GOMSiteDoc />} />
                      <Route path="/documentation/gom/ComponentGroupLookups" element={<ComponentGroupLookupDoc />} />
                      <Route path="/documentation/gom/ComponentMappings" element={<ComponentMappingDoc />} />
                      <Route path="/documentation/gom/Translations" element={<TranslationDoc />} />
                      <Route path="/documentation/gom/SampleFind" element={<GOMSampleFind />} />
                      <Route path="/documentation/gom/Process" element={<GomProcess />} />

                      <Route path="/support" element={<SupportLanding />} />

                      {admin &&
                          <>
                            <Route path="/Architecture" element={<Architecture />} />
                            <Route path="/Resources" element={<Resources />} />
                          </>
                      }

                  </Routes>
                  <Footer />
              </SiteAccessProvider>
          </AlertProvider>
    </>
  );
}

export default App;
