import React, { useState, useEffect, useContext } from 'react';

import SiteAccessContext from '../../../contexts/SiteAccessContext';

import { useMsal } from '@azure/msal-react';
import { fetchDataAuthenticated } from '../../../utilities/HttpUtils';
import { usePrivileges } from '../../../hooks/usePrivileges';

import useAlert from '../../Alerts/useAlert';

import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell
} from '@mui/x-data-grid';
import {
  randomId
} from '@mui/x-data-grid-generator';

function ILRComponentMappingForm(props) {
  const _apiUrl = process.env.REACT_APP_API_URL;


    const { assignedSites } = useContext(SiteAccessContext);

  const [rowModesModel, setRowModesModel] = React.useState({});
  const [siteDetails, setSiteDetails] = useState([]);

  const defaultRow = {
    ilrComponentMapHeaderId: 0,
    siteName: "",
    product: "",
    ilrComponentMapDetailInfo: [],
    modifiedBy: "",
    modifiedOn: ""

  };
  const AddComponentflag = (props && props.SelectedValueTochild && props.SelectedValueTochild != "") ? false : true;
  const SelectedValue = (props && props.SelectedValueTochild && props.SelectedValueTochild != "") ? JSON.parse(props.SelectedValueTochild) : defaultRow;
  const [rows, setRows] = React.useState(SelectedValue == "" ? [] : SelectedValue.ilrComponentMapDetailInfo);
  const [CompValue, SelectedCompValue] = React.useState(SelectedValue);
  const [site, setSite] = React.useState(SelectedValue.siteName);
    const [detailOrderNumber, setDetailOrderNumber] = React.useState(1);

    const { accounts } = useMsal();

    const { contributor } = usePrivileges();

    const { setAlert } = useAlert();

    const username = accounts[0] && accounts[0].username;

  const EditToolbar = (props) => {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
      setDetailOrderNumber(detailOrderNumber + 1);
      const id = randomId();
      const date = new Date();
        setRows((oldRows) => [...oldRows, { id, ilrComponentMapHeaderId: 0, ILRComponentMapDetailId: 0, analysis: '', component: '', sapCharId: '', resultType: '', unitOfMeasure: '', decimalPlaces: 0, order: detailOrderNumber, modifiedBy: username, modifiedDate: date, isNew: true }]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add record
        </Button>
      </GridToolbarContainer>
    );
  }

  const handleChangeSite = (event) => {
    setSite(event.target.value);
    CompValue.siteName = event.target.value;
    SelectedCompValue(CompValue);
    // GetComponentGroupNames();
  };
  const CloseDialoginParent = (flag) => {
    props.CloseDialog(flag);
  };

  const saveComponentValues = async () => {
    const saveBtn = document.getElementById('btnSave');
    const serviceUrl = _apiUrl + (AddComponentflag ? "AddIlrComponentMap" : "UpdateILRComponentMap")

        if (CompValue.siteName == null || CompValue.siteName === undefined || CompValue.siteName === "") {
            setAlert("primary", "Validation", "Please select Site");
        return false;
      }
        if (CompValue.product == null || CompValue.product === undefined || CompValue.product === "") {
            setAlert("primary", "Validation", "Please enter Product");
        return false;
      }
      CompValue.modifiedBy = username;
      saveBtn.disabled = true;
      await fetchDataAuthenticated(serviceUrl, "POST", JSON.stringify(CompValue))
          .then(response => {
              if (response.ok) {
                  saveBtn.disabled = false;
                  return response.json();
                  
              }
              throw new Error('Something went wrong.');
          })
          .then(response => {
              if (response.result)
                  CloseDialoginParent(true);
                  saveBtn.disabled = false;
              setAlert("success", "Success", response.message);
          })
          .catch(error => {
              setAlert("danger", "Error", error.message);
              console.error(error);
              saveBtn.disabled = false;
          });

  };
    const LoadIlrSiteDetails = async () => {

        let params = new URLSearchParams();

        if (assignedSites && assignedSites.length > 0) {

            assignedSites.map(si => params.append("sites", si.siteName));

            await fetchDataAuthenticated(_apiUrl + "IlrUniqueSiteDetails?" + params.toString())
                .then(response => {
                    if (response.ok && response.status == 200) {
                        return response.json();
                    }
                    else if (response.status == 204) {
                        return {};
                    }
                    throw new Error('Something went wrong.');
                })
                .then((data) => {
                    setSiteDetails(data);
                })
                .catch(error => {
                    setAlert("danger", "Error", error.message);
                    console.error(error);
                });
        }
        else {
            console.error("In LoadIlrSiteDetails - You have no access to sites");
        }

    }

  useEffect(() => {
    LoadIlrSiteDetails();
  }, [assignedSites]);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (row) => async () => {
    setRows(rows.filter((row) => row.id !== row.id));

    const ComponentMapFields = {
      ILRComponentMapHeaderId: row.ilrComponentMapHeaderId,
      ModifiedBy: username,
      Active: false
    };

      await fetchDataAuthenticated(_apiUrl + 'DeleteIlrComponentMap', "POST", JSON.stringify(ComponentMapFields))
          .then(response => {
              if (response.ok) {
                  return response.json();
              }
              throw new Error('Something went wrong.');
          })
          .then(response => {
              setAlert("success", "Success", response.message);
          })
          .catch(error => {
              setAlert("danger", "Error", error.message);
              console.error(error);
          });

  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    const updatedRows = rows.map((row) => (row.id == newRow.id ? updatedRow : row));
    setRows(updatedRows);
    CompValue.ilrComponentMapDetailInfo = updatedRows;
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleTextFieldChange = (event) => {
    CompValue[event.target.id] = event.target.value;
    SelectedCompValue(CompValue);
  };

    const columns = [
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ row }) => {
                const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(row.id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(row.id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(row.id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(row)}
                        color="inherit"
                    />,
                ];
            },
        },
    { field: 'id', headerName: 'Id', width: 0, editable: true },
    { field: 'ilrComponentMapHeaderId', headerName: 'Component Group Id', width: 0, editable: true },
    { field: 'ilrComponentMapDetailId', headerName: 'Component Group Detail Id', width: 0, editable: true },
    {
      field: 'analysis',
      headerName: 'Analysis',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      editable: true,
    },
    {
      field: 'component',
      headerName: 'Component',
      width: 200,
      editable: true,
    },
    {
      field: 'sapCharId',
      headerName: 'SAP Char Id',
      width: 100,
      editable: true,
      preProcessEditCellProps: (params) => {
            const hasError = params.props.value.length > 4;
            if (hasError) {
                setAlert("danger", "Error", "SAPCharId should be 4 characters length");
            }
            return { ...params.props, error: hasError };
        },
    },
    {
      field: 'resultType',
      headerName: 'Result Type',
      width: 100,
      editable: true,
      preProcessEditCellProps: (params) => {
            const hasError = params.props.value.length > 1;
            if (hasError) {
                setAlert("danger", "Error", "Result Type should be only one character length");
            }
            return { ...params.props, error: hasError };
        },
    },
    {
      field: 'unitOfMeasure',
      headerName: 'Unit Of Measure',
      width: 150,
      editable: true
    },
    {
      field: 'decimalPlaces',
      headerName: 'Decimal Places',
      type: 'number',
      width: 100,
      align:'left',
      editable: true
    },
    {
      field: 'order',
      headerName: 'Order',
      type: 'number',
      width: 100,
      align:'left',
      editable: true
    },
    {
      field: 'active',
      headerName: 'Active',
      type: 'boolean',
      width: 100
    },
    {
      field: 'modifiedBy',
      headerName: 'Modified By',
      width: 200
    },
    {
      field: 'modifiedDate',
      headerName: 'Modified Date',
      width: 150
    }
  ];

  return (
    <DialogContent>
      <div className='container-fluid csDialogbottom'>
        <div className='container-fluid csDialogbottom'>
          <div className='row mt-2'>
            <div className='col-md-6'>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="lblsiteId" className='required'>Site</InputLabel>
                  <Select
                    labelId="lblSiteId"
                    id="isiteId"
                    value={site}
                    label="Site *"
                    onChange={handleChangeSite}
                  >
                    <MenuItem value="">Select Site</MenuItem>
                    {siteDetails.map((sitedt, index) => {
                      return (
                        <MenuItem value={sitedt.siteName} key={"lblsiteId" + sitedt.siteId + index}>{sitedt.siteName}</MenuItem>
                      );
                    })
                    }
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className='col-md-6'>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '& > :not(style)': { m: 0 },
                }}
              >
                <TextField required
                  id="product"
                  label="Product"
                  defaultValue={CompValue.product}
                  onChange={handleTextFieldChange}
                  inputProps={{ maxLength: 20 }}
                />
              </Box>
            </div>
          </div>

        </div>

        <div className='clearfix'></div>
        <div className='col-md-12'>
          <Box>
            <DataGrid autoHeight
              rows={rows}
              columns={columns}
              editMode="row"
              initialState={{
                ...rows.initialState,
                pagination: { paginationModel: { pageSize: 5 } },
              }}
              pageSizeOptions={[5, 10, 25]}
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              getRowId={(row) => row.id}
              columnVisibilityModel={{ ilrComponentMapDetailId: false, id: false, ilrComponentMapHeaderId: false }}
              slots={{
                toolbar: EditToolbar,
              }}
              slotProps={{
                toolbar: { setRows, setRowModesModel },
              }}
            />
          </Box>
        </div>
        <div className='clearfix'></div>
              <div className='row'>

                  {/* TODO: Investigate the columns used here as they seem to be determining the size of buttons */}
          <div className='col-md-9'></div>
          <div className='col-md-3 row'>
            <div className='col-md-6'>
              <button type="button" className="btn btn-outline-secondary" onClick={CloseDialoginParent}>Cancel</button>
            </div>
            <div className='col-md-6'>
              <button type="button" disabled= {!contributor ? true : false} id="btnSave" className="btn btn-outline-primary" onClick={saveComponentValues}>Save</button>
            </div>
          </div>
        </div>
      </div>
    </DialogContent>
  );
}
export default ILRComponentMappingForm;