import React from 'react'
import { Link } from 'react-router-dom';
import GOMComponentLookupGrid from "../../../../Images/GOMComponentLookupGrid.jpg";
				
function ComponentGroupLookupDoc(props) {
    return(
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-10 titleBar'>
                    <h4>GOM Component Group Lookup Header</h4>
                </div>
                <div className='col-md-2'>
                    <Link to="/gom">
                        <button className="btn btn-outline-primary btn-primary-label">Back</button>
                    </Link>
                </div>
            </div>
            <div>
                
                <h5>GOM Component Group Lookup</h5>
                <p>The Component Group Lookup maps the GOM Request to the component group.</p>
                <p>The interface will search by the following until a records is found:
                    <ul>
                        <li>Product, Language, Customer</li>
                        <li>Product, Language</li>
                        <li>Product</li>
                    </ul>
                </p>
                <p>
                <ul>
                    <li><b>Site</b> SampleManager instance or site to relate records in this Cert App to other data.</li>
                    <li><b>Product</b> must match the product or formula number from GOM and a MLP in SampleManager.</li>
                    <li><b>Language</b> defaults to en for English.  zh is used for Chinese with zh being the ISO language code.  Other languages can be support from the this Cert App,
                        but would need to be coordinated with GOM.</li>
                    <li><b>Customer</b> can be used to specify a compnent group by customer.</li>
                    <li><b>Component Group Name</b> must be a valid name for a Component Group assigned to the same site.</li>
                    <li><b>Description</b> is any descriptive value.</li>
                    <li><b>Active</b> is used to deactive a record not currently in use if desired by the site for tracking.</li>
                    </ul>
                </p>
                <p />
                <img src={GOMComponentLookupGrid} className="GOMComponentLookupGrid" alt="GOM Component Lookup Grid" />

            </div>

        </div>
    );
}
export default ComponentGroupLookupDoc;