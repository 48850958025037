import React from 'react';
import { Link } from 'react-router-dom';

function SupportLanding(props) {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-10 titleBar'>
                </div>
                <div className='col-md-2'>
                    <Link to="/documentation">
                        <button className="btn btn-outline-primary btn-primary-label">Back</button>
                    </Link>
                </div>
            </div>
            <div align="center">
                <ul>
                    <li><Link to='/Architecture' className='submenu-item'>Architecture</Link></li>
                    <li><Link to='/IlrProcess' className='submenu-item'>Processing the ILR</Link></li>
                    <li><Link to='/IlrStatus' className='submenu-item'>Status of ILR</Link></li>
                    <li><Link to='/GomProcess' className='submenu-item'>Processing the GOM Request</Link></li>
                    <li><Link to='/Resources' className='submenu-item'>Resources</Link></li>
                </ul>
            </div>
        </div>
    )
}

export default SupportLanding;
