import React from 'react'
import { Link } from 'react-router-dom';

function GOMProcess(props) {
    return(
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-10 titleBar'>
                    <h4>GOM Process</h4>
                </div>
                <div className='col-md-2'>
                    <Link to="/support">
                        <button className="btn btn-outline-primary btn-primary-label">Back</button>
                    </Link>
                </div>
            </div>
            <div>
                
                <h5>GOM Processing</h5>
                <p>Processing of the GOM request is a request/reply type of interface.  The User waits at the GOM screen for the reply to be processed and the
                    Certificate of Quality to be produced by GOM (SAP).  SampleManager only provides the Quality Data for the CofQ.  GOM provides the customer data,
                    formating into pdf, comments and any retention of the CofQ after it is issued.
                </p>
                <p>The Sample must be ready when GOM makes the request or an error will be reported to GOM.  The key to the sample being ready is the STATUS_COQ or XOM_STATUS_COQ
                    and the WAIVER_FINAL or XOM_WAIVER_FINAL fields on the sample record.  If the STATUS_COQ is "T" or the WAIVER_FINAL is populated with any value, the sample is
                    considered ready for CofQ.  This allows the CofQ to be issues as soon as any analysis considered critical to the CofQ are completed and either in spec or
                    off spec values waived by the lab.
                </p>
                <p> A component group or list of components is used to determine what results are sent to GOM for each sample.  This component group has a header table,
                    ComponentGroupHeader, with information such as LEVEL_ID to specify the Level of the MLP limits to be send with the data and parameters to use in the
                    search for the sample.
                </p>
                <p> The component groups are associated with the request through the ComponentGroupLookup table.  This allows the site to map a request using the Product, Langague and
                    optionally the Customer.  Generally the sites choose to create component groups to match products, but some are able to map multiple products to a single component
                    group where the analysis list is similar.  The interface will send the reply by matching the components in the Component Group with Analysis and Components on the
                    sample ignoring any components that do not have a match.  That is, by design, the components are not required and there is no verification that any particular
                    component is available.
                </p>
                <p>Analysis and Components on the sample are mapped to Customer Components in the ComponentGroupDetails table.  There is an additional column for Government Components
                    incase there is a need to issue CofQ's for US Government Contracts.
                </p>
                <p />
                <h5>Trouble Shooting</h5>
                <p>The Logs should have significant information about any issues that have occured.  Filter by Site, Dates and Log Type = GOM to limit the search.  The Details will
                    open the messages.  The messages are formated in Json for flexibility in storing many different type of log messages.
                </p>
                <p>Any errors that could be anticipated have been listed as numbers and are sent back to GOM.  These should be displayed to the GOM users to help with troubleshooting.
                    Error Codes are Enumerated in the \Enums\ErrorCodes.cs in the source code.
                </p>
                <p>The request from GOM and the Reply back are JSON documents and stored in the logs.  If there are questions about what was recieved or sent back, those documents should
                    be available for review.
                </p>


            </div>

        </div>
    );
}
export default GOMProcess;