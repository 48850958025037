import React from 'react';
import { Link } from 'react-router-dom';

function DocLand(props) {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-10 titleBar'>
                </div>
                <div className='col-md-2'>
                    <Link to="/">
                        <button className="btn btn-outline-primary btn-primary-label">Back</button>
                    </Link> 
                </div>
            </div>
            <div align="center">
                <ul>
                    <li><Link to='/gom' className='submenu-item'>GOM</Link></li>
                    <li><Link to='/gems' className='submenu-item'>GEMS</Link></li>
                    <li><Link to='/support' className='submenu-item'>Support</Link></li>
                </ul>
            </div>
        </div>
    )
}

export default DocLand;
