import React from 'react'
import { Link } from 'react-router-dom';
import ILRSiteForm from "../../../../Images/ILRSiteForm.jpg";
import ILRSiteGrid from "../../../../Images/ILRSiteGrid.jpg";

function ILRSiteDoc(props) {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-10 titleBar'>
                    <h4>ILR Site Header</h4>
                </div>
                <div className='col-md-2'>
                    <Link to="/gems">
                        <button className="btn btn-outline-primary btn-primary-label">Back</button>
                    </Link>
                </div>
            </div>
            <div>
 
                <h5>ILR Site Mapping</h5>
                <ul>
                    <li><b>SAP Subsystem</b> matches the Sub System in GEMS.</li>
                    <li><b>Plant Code</b> Matches the Plant Code in GEMS.</li>
                    <li><b>Site Name</b> SampleManager instance or site to relate records in this Cert App to other data.</li>
                    <li><b>Description</b> is any descriptive value.</li>
                    <li><b>Sample Find Routine</b> code to find sample. Description of Routines is on the Sample Find documenation page.</li>
                    <li><b>SM API Connection</b> is provided by support and used to call the SM-API.  The SM-API provides the connection to the database.</li>
                </ul>
                <p />
                <img src={ILRSiteGrid} className="ILRSiteGrid" alt="ILR Site Grid" />
                <p />
                <ul>
                    <li><b>Add ILR Site</b> to create a new records for a SAP Subsytem and Plant Code.</li>
                    <li><b>Edit Button</b> brings up the editor for a specific record.</li>
                </ul>
                <p />
                <img src={ILRSiteForm} className="ILRSiteForm" alt="ILR Site Form" />

            </div>
        </div>
    );
}
export default ILRSiteDoc;