import React from 'react'
import { Link } from 'react-router-dom';
import ILRMaterialMap from "../../../../Images/ILRMaterialMap.jpg";

function ILRMaterialMapDoc(props) {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-10 titleBar'>
                    <h4>ILR Material Map Header</h4>
                </div>
                <div className='col-md-2'>
                    <Link to="/gems">
                        <button className="btn btn-outline-primary btn-primary-label">Back</button>
                    </Link>
                </div>
            </div>
            <div>

                <h5>ILR Material Map</h5>
                <ul>
                    <li><b>Site</b> is the SampleManager instance and selected from a list provided by support.</li>
                    <li><b>SMProduct</b> SampleManager MLP Header.Identity used for the Product on the Sample</li>
                    <li><b>SAPMaterialGroup</b> GEMS Material Group and always starts with 1.  This is not the SAP Material Number that starts with a 5.  Each SAP Material Group for each site requires a mapping record.</li>
                    <li><b>MLP Level</b> MLP Level used to verify SampleManager specifications against the GEMS Request</li>
                    <li><b>SampleTemplate</b> If a Sample Login is triggered by the GEMS request, for SM11 (GRC) this is the Sample Login Template.  For SM12 and later this is the Sample Workflow.</li>
                </ul>
                <p />
                <img src={ILRMaterialMap} className="ILRMaterialMap" alt="ILR Material Map" />

            </div>

        </div>
    );
}
export default ILRMaterialMapDoc;