import React from 'react'
import { Link } from 'react-router-dom';
import IlrAzureFunctions from "../../../../Images/IlrAzureFunctions.png";

function IlrProcess(props) {
    return(
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-10 titleBar'>
                    <h4>GEMS Process</h4>
                </div>
                <div className='col-md-2'>
                    <Link to="/support">
                        <button className="btn btn-outline-primary btn-primary-label">Back</button>
                    </Link>
                </div>
            </div>
            <div>
                
                <h5>GEMS ILR Azure Functions</h5>
                <p>The GEMS interface is a store and reply interface.  GEMS sends a request called an ILR to the CertApp and the CertApp processes the request and sends back a
                    reply when the request has been validated and the sample is authorized in SampleManager.  There may be a considerable delay in sending the reply or the reply may go
                    back to GEMS within minutes of being received.  It depends on the site and how they handle teh samples and Production Write on that triggers the ILR from GEMS.
                </p>
                <p>This 2024 version of the GEMS interface is a major change from the original interface written in 2003.
                    <ul>
                        <br />
                        <li ><b>-</b> The request is stored in the SQL Server CertApp database using the tables ILRInspectionLotRequest, ILRCharacteristicSpecifications and ILRCharacteristicInspectionCatalog.
                            These tables have been created and named to reflect as much as practical, the structure of the JSON packet that GEMS sends. </li>
                        <br />
                        <li><b>-</b> The fixed length text files have been replaced by JSON packets posted from XI to the CertApp and from the CertApp to XI.  There are no text files
                            transferred or stored with this CertApp.</li>
                        <br />
                        <li><b>-</b> The original code was written in SampleManager using VGL and the data was stored in the SampleManager Database in the SAP* tables.  This version uses C#, 
                            REACT for the user interface, Azure to serve the User Interface and publish the code and an Azure SQL Server database.
                        </li>
                        <br />
                        <li><b>-</b> The VGL Code that sets the decimal places on the result in SampleManager remains in SampleManager and is not part of this update. </li>
                        <br />
                        <li><b>-</b> While this CertApp is being created specifically for the China 1 project in 2024, the intention is that eventually it will replace the SampleManager version created in 2003. </li>
                    </ul>
                </p>
                <p>A user configurable Sample Find function is provided to support various method of associating the Request with a sample including a method to trigger a sample login in SampleManager
                     and a function to cancel the request with no further processing.  The cancel function is provided to reduce processing where the site has not configured the interface of prefers to
                     manually enter the data in GEMS.
                </p>
                
                <p>The request is validated against the setup of the components in the CertApp and the MLP's from SampleManager for units of measure, decimal places, and specification limits.  It is important
                    that the results sent by the CertApp from the SampleManager results use the same decimals and units of measure as GEMS and be checked against the same limits to prevent incorrect release of product.
                </p>
                <p>A method provided on the screen to allow the user to restart processing in the event that the request in on hold for validation issues, was cancelled but is now needed or if
                    the results are needed immediately.  Restarting the processing of the ILR will not cause conflicts with any messages already on the Service Bus.
                </p>
                <p>The request from GEMS and the Reply back are JSON documents and stored in the log table.  Various steps in the processing will also trigger the storage of information in the log table.
                    If there are issues or questions about the processing of the request, those log entries are available for review.
                </p>

                <p />
                <img src={IlrAzureFunctions} className="IlrAzureFunctions" alt="Ilr Azure Functions" />

            </div>

        </div>
    );
}
export default IlrProcess;