import React, { useState, useEffect, useContext } from 'react'

import SiteAccessContext from '../../../contexts/SiteAccessContext';

import { fetchDataAuthenticated } from '../../../utilities/HttpUtils';
import { usePrivileges } from '../../../hooks/usePrivileges';

import useAlert from '../../Alerts/useAlert';

import '../../DataGrid.css';
import gridLogo from "../../../Images/grid.svg";
import ILRComponentMappingForm from './ILRComponentMappingForm';

// Styling
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

function ILRComponentMappingGrid(props) {
    const _apiUrl = process.env.REACT_APP_API_URL;
    const [tableData, setTableData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [SelectedValue, setSelectedValue] = useState('');

    const { assignedSites } = useContext(SiteAccessContext);

    const { contributor } = usePrivileges();

    const { setAlert } = useAlert();

    useEffect(() => {
        LoadILRComponentMapList();
    }, [assignedSites])

    const GroupDetails = (params) => {
        return (
            <strong>
                <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => {
                    openDetailsComponent(params.row);
                }}>Details</button>
            </strong>
        )
    }
    const DeleteRow = (params) => {
        return (
            <strong>
                <button id='activate' type="button" disabled= {!contributor ? true : false} className="btn btn-outline-primary btn-sm" onClick={() => {
                    DeleteILRComponentMap(params.row, true);
                }}>Delete</button>
            </strong>
        )
    }

    var _column = [
        {
            field: 'siteName',
            headerName: 'Site Name',
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'product',
            headerName: 'Product',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'active',
            headerName: 'Active',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'modifiedBy',
            headerName: 'Modified By',
            width: 250,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'modifiedDate',
            headerName: 'ModifiedDate',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        }
    ];

    //if (props.PostData.roleId != 3) {
    _column.push(
        {
            field: '-',
            headerName: 'Details',
            width: 100,
            renderCell: GroupDetails,
            disableClickEventBubbling: true,
            headerAlign: 'center',
            align: 'center'
        });
    //}

    //if (admin) {
        _column.push({
            field: '/',
            headerName: 'Delete',
            width: 100,
            renderCell: DeleteRow,
            disableClickEventBubbling: true,
            headerAlign: 'left',
            align: 'left'
        });
    //}

    var columns: GridColDef[] = _column;

    const openEditComponent = (params) => {
        setOpen(true);
        setSelectedValue(JSON.stringify(params).toString());
    };

    const openDetailsComponent = (params) => {
        setOpen(true);
        setSelectedValue(JSON.stringify(params).toString());
    };

    const openAddComponent = (params) => {
        setOpen(true);
        setSelectedValue("");
    };

    const handleFormClose = (response) => {
        if (response != null && response == true) {
            LoadILRComponentMapList();
        }
        setOpen(false);
    };

    const DeleteILRComponentMap = async (rowData, isActive) => {

        const ComponentMapFields = {
            ILRComponentMapHeaderId: rowData.ilrComponentMapHeaderId
            // ModifiedBy: username, // TODO: Should this be set? or can the line be removed?
            // Active: isActive
        };

        await fetchDataAuthenticated(_apiUrl + 'DeleteIlrComponentMap', "POST", JSON.stringify(ComponentMapFields))
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong.');
            })
            .then(response => {
                setAlert("success", "Success", response.message);
                LoadILRComponentMapList();
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
                console.error(error);
            });

    };

    const LoadILRComponentMapList = async () => {
        // var now = new Date();
        // console.log(now.toISOString() + " >>>> Entering LoadILRComponentMapList");

        let params = new URLSearchParams();
        
        if (assignedSites && assignedSites.length > 0) {
            assignedSites.map(si => params.append("sites", si.siteName));

            await fetchDataAuthenticated(_apiUrl + "ILRComponentMap?" + params.toString())
                .then(response => {
                    if (response.ok && response.status == 200) {
                        return response.json();
                    }
                    else if(response.status == 204)
                    {
                        return {};
                    }
                    throw new Error('Something went wrong.');
                })
                .then(data => {
                    setTableData(data);
                })
                .catch(error => {
                    console.error(error);
                    setAlert("danger", "Error", error.message);
                });
        }
        else {
            console.error("In LoadILRComponentMapList - You have no access to sites");
        }
    };

    if (!assignedSites) {
        return (
            <div className="d-flex justify-content-center">
                <p className="text-muted">You have no access to sites. Please contact your administrator.</p>
            </div>
        );
    }

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-9 titleBar'>
                    <img src={gridLogo} className="gridLogo" />
                    ILR Component Mapping
                </div>
                <div className='col-md-3'>
                    {/*TODO: implement this correctly: props.PostData.roleId != 2*/}
                    {<button type="button" disabled= {!contributor ? true : false} className="btn btn-outline-primary btn-primary-label" onClick={openAddComponent}>ILR Component Mapping</button>}
                </div>
            </div>

            <Box sx={{ height: 375, width: '100%' }}>
                <DataGrid
                    rows={tableData}
                    columns={columns}
                    initialState={{
                        ...tableData.initialState,
                        pagination: { paginationModel: { pageSize: 5 } },
                      }}
                    pageSizeOptions={[5, 10, 20,50,100]}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                    getRowId={(row: any) => row.ilrComponentMapHeaderId}
                />
            </Box>

            <Dialog open={open} onClose={handleFormClose} fullWidth maxWidth="md">
                <DialogTitle className="csDialogTitle">ILR Component Mapping</DialogTitle>
                <ILRComponentMappingForm SelectedValueTochild={SelectedValue} PostData={props.PostData} CloseDialog={handleFormClose} />
            </Dialog>
        </div>
    );
}
export default ILRComponentMappingGrid;