import React, { useState, useEffect, useContext } from 'react';

import SiteAccessContext from '../../../contexts/SiteAccessContext';

import { fetchDataAuthenticated } from '../../../utilities/HttpUtils';

import useAlert from '../../Alerts/useAlert';

import gridLogo from "../../../Images/grid.svg";
import LogInfo from './LogInfo';
import '../../DataGrid.css';

// Styling
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

function LogDetails(props) {
    const _apiUrl = process.env.REACT_APP_API_URL;
    const [tableData, setTableData] = useState([]);
    const [siteDetails, setSiteDetails] = useState([]);
    const [site, setSite] = React.useState();
    const [fromDateLogged, setFromDateLogged] = React.useState();
    const [toDateLogged, setToDateLogged] = React.useState();
    const [openForm, setFormOpen] = React.useState(false);
    const [SelectedValue, setSelectedValue] = useState('');

    const { assignedSites } = useContext(SiteAccessContext);

    const { setAlert } = useAlert();

    const [isLogType, setLogType] = React.useState(0);
    const handleChangeLogType = (event) => {
        setLogType(event.target.value);
    };

    const handleChangeSite = (event) => {
        setSite(event.target.value);
    };

    const EditRow = (params) => {
        return (
            <strong>
                <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => {
                    openEditUser(params.row);
                }}>Detail</button>
            </strong>
        )
    }
    var _column = [
        {
            field: 'site',
            headerName: 'Site',
            width: 80,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'category',
            headerName: 'Category',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'event',
            headerName: 'Event',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'dateLogged',
            headerName: 'Date Logged',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'detail',
            headerName: 'Detail',
            width: 500,
            headerAlign: 'center',
            align: 'center'
        }];
    _column.push(
        {
            field: '-',
            headerName: 'Action (Detail)',
            width: 150,
            renderCell: EditRow,
            disableClickEventBubbling: true,
            headerAlign: 'center',
            align: 'center'
        });

    var columns: GridColDef[] = _column;

    const openEditUser = (params) => {
        setSelectedValue(JSON.stringify(params).toString());
        setFormOpen(true);
    };
    const handleFormClose = (response) => {
        if (response != null && response == true) {
            LoadLogs();
        }
        setFormOpen(false);
    };
    const handleFromDateTextFieldChange = (event) => {
        setFromDateLogged(event.target.value)
    };

    const handleToDateTextFieldChange = (event) => {
        setToDateLogged(event.target.value)
    };
    const LoadLogs = async () => {

        const LoadMapFields = {
            Site: site,
            FromDateLogged: fromDateLogged,
            ToDateLogged: toDateLogged,
            LogType: isLogType
        };

        await fetchDataAuthenticated(_apiUrl + 'Logs', "POST", JSON.stringify(LoadMapFields))
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong.');
            })
            .then((response) => {
                setTableData(response);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
                console.error(error);
            });
    };

    const LoadSiteDetails = async () => {

        let params = new URLSearchParams();

        if (assignedSites && assignedSites.length > 0) {

            assignedSites.map(si => params.append("sites", si.siteName));

            await fetchDataAuthenticated(_apiUrl + "UniqueGomSiteDetails?" + params.toString())
                .then(response => {
                    if (response.ok && response.status == 200) {
                        return response.json();
                    }
                    else if(response.status == 204)
                    {
                        return {};
                    }
                    throw new Error('Something went wrong.');
                })
                .then((data) => {
                    setSiteDetails(data);
                })
                .catch(error => {
                    setAlert("danger", "Error", error.message);
                    console.error(error);
                });
        }
        else {
            console.error("In LoadComponentGroupLookupList - You have no access to sites");
        }
    }

    useEffect(() => {
        LoadSiteDetails();
    }, [assignedSites]);

    const getFromDate = () => {
        // Today
        let fromDate = new Date();

        return fromDate.toISOString().split('T')[0];
    }

    const getToDate = () => {
        // Today
        let fromDate = new Date();

        return fromDate.toISOString().split('T')[0];
    }

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-3 titleBar'>
                    <img src={gridLogo} className="gridLogo" />
                    Logs
                </div>
            </div>

            <div className='row'>

                <div className='col-md-2'>
                    <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                            <InputLabel id="lblsiteId">Site</InputLabel>
                            <Select
                                labelId="lblSiteId"
                                id="isiteId"
                                value={site}
                                label="Site"
                                onChange={handleChangeSite}
                            >
                                <MenuItem value="">Select Site</MenuItem>
                                {siteDetails.map((sitedt, index) => {
                                    return (
                                        <MenuItem value={sitedt.siteName} key={"lblsiteId" + sitedt.siteId + index}>{sitedt.siteName}</MenuItem>
                                    );
                                })
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </div>
                <div className='col-md-2'>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            '& > :not(style)': { m: 0 },
                        }}
                    >
                        <TextField
                            id="fromDateLogged"
                            label="From Date Logged"
                            type="date"
                            defaultValue={ getFromDate() }
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleFromDateTextFieldChange}

                        />
                    </Box>
                </div>
                <div className='col-md-2'>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            '& > :not(style)': { m: 0 },
                        }}
                    >
                        <TextField
                            id="toDateLogged"
                            label="To Date Logged"
                            type="date"
                            defaultValue={ getToDate() }
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleToDateTextFieldChange}

                        />
                    </Box>
                </div>
                <div className='col-md-2'>
                    <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                            <InputLabel id="lblIsActive">Log Type</InputLabel>
                            <Select
                                labelId="lblIsActive"
                                id="iIsActive"
                                value={isLogType}
                                onChange={handleChangeLogType}
                                label="Log Type"
                            >
                                <MenuItem value={1}>GOM</MenuItem>
                                <MenuItem value={2}>GEMS</MenuItem>
                                <MenuItem value={3}>Diagnostic</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </div>
                <div className='col-md-4'>
                    {<button type="button" className="btn btn-outline-primary btn-primary-label" onClick={LoadLogs}>Filter</button>}
                </div>
            </div>
            <div className='clearfix'></div>
            <Box sx={{ height: 300, width: '100%' }}>
                <DataGrid
                    rows={tableData}
                    columns={columns}
                    initialState={{
                        ...tableData.initialState,
                        pagination: { paginationModel: { pageSize: 5 } },
                      }}
                    pageSizeOptions={[5, 10, 20,50,100]}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                    getRowId={(row: any) => row.logId}
                />
            </Box>

            <Dialog open={openForm} onClose={handleFormClose} fullWidth maxWidth="md">
                <DialogTitle className="csDialogTitle">Log Detail</DialogTitle>
                <LogInfo SelectedValueTochild={SelectedValue} PostData={props.PostData} CloseDialog={handleFormClose} />
            </Dialog>
        </div>
    );
}
export default LogDetails;