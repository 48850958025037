import { useState, useEffect } from 'react';
import useAlert from '../Components/Alerts/useAlert';
import { fetchDataAuthenticated } from '../utilities/HttpUtils';

export function useUsers(siteId) {

    const _apiUrl = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);

    const { setAlert } = useAlert();

    const [users, setUsers] = useState([]);

    useEffect(() => {
        if (siteId) {
            getUsers(siteId);
        }
    }, [siteId]);

    async function getUsers(siteId) {

        let url = _apiUrl + "api/user";

        if (siteId) {
            url += `?siteId=${siteId}`
        }

        await fetchDataAuthenticated(url)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setUsers(data);
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Failed to get users", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { users, loading };
}

export function useUserSites() {

    const _apiUrl = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);

    const [userSites, setUserSites] = useState();

    useEffect(() => {
        getUserSites();
    }, []);

    async function getUserSites() {

        await fetchDataAuthenticated(_apiUrl + "api/user/sites")
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setUserSites(data);
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { userSites, loading };
}