import React from 'react';
import { Link } from 'react-router-dom';

function GOMLanding(props) {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-10 titleBar'>
                </div>
                <div className='col-md-2'>
                    <Link to="/documentation">
                        <button className="btn btn-outline-primary btn-primary-label">Back</button>
                    </Link>
                </div>
            </div>
            <div align="center">
                <ul>
                    <li><Link to='/documentation/gom/sites' className='submenu-item'>GOM Site</Link></li>
                    <li><Link to='/documentation/gom/ComponentGroupLookups' className='submenu-item'>Component Group Lookup</Link></li>
                    <li><Link to='/documentation/gom/ComponentMappings' className='submenu-item'>Component Mapping</Link></li>
                    <li><Link to='/documentation/gom/Translations' className='submenu-item'>Translations</Link></li>
                    <li><Link to='/documentation/gom/SampleFind' className='submenu-item'>Sample Find</Link></li>
                </ul>
            </div>
        </div>
    )
}

export default GOMLanding;
